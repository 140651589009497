import InfoDisplay from "../components/abstract/InfoDisplay";
import Typography from '@mui/material/Typography';
import Loading from "../components/statics/Loading";
import {Box, Button, Grid, Fab, Fade, useScrollTrigger, Tooltip, IconButton} from "@mui/material";
import {getPubliclyVisibleProjects} from '../ApiClient'
import {useState, useEffect, useRef} from 'react'
import {useNavigate} from "react-router-dom";
import {useAuth} from '../hooks/useAuth'
import {isProject, isFailRes} from '../guards'
import KeyboardArrowUpSharpIcon from '@mui/icons-material/KeyboardArrowUpSharp';
import SendIcon from '@mui/icons-material/Send';
import useRedirectAuthSafe from "../hooks/useRedirectAuthSafe";
import Header from "../components/styled/Header";
import Divider from "@mui/material/Divider";
import { useErrorBoundary } from "react-error-boundary";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";


export function ProjectsView() {
    const [projects, setProjects] = useState<Project[]>()
    const [message, setMessage] = useState<undefined | string>()
    const [flag, setFlag] = useState<number>(0)
    const topRef = useRef<null | HTMLDivElement>(null)
    const trigger = useScrollTrigger({
        target: window,
        disableHysteresis: true,
        threshold:300,
    })
    const {showBoundary} = useErrorBoundary()
    const rAS = useRedirectAuthSafe()
    const {auth} = useAuth()
    useEffect(()=> {
        getPubliclyVisibleProjects()
            .then((res) => {
                if (Array.isArray(res) && isProject(res[0]) ) {
                    setProjects(res)
                }
                else if (isFailRes(res)) {
                    setMessage(res.reason)
                }
            })
            .catch((e) => {showBoundary(e)})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const scrollToTop = () => {
        topRef.current?.scrollIntoView({behavior:"smooth"})
    }

    const pageForward = (i:number) => {
        setFlag(i)
        topRef.current?.scrollIntoView({behavior:'auto'})
    }
    const pageBack = () => {
        if (flag-20 >= 0) {
            setFlag(flag-20)
        }
        else {
            setFlag(0)
        }
        topRef.current?.scrollIntoView({behavior:'auto'})
    }


    if (projects) {
        let applicableProjects = []
        let expiredProjects = []
        let i = flag
        for (i; i<(flag+20) && i<projects.length;i++) {
            let p = projects[i]
            let datestring = p.deadline.split('-')
            let m = parseInt(datestring[1])
            let d = parseInt(datestring[2])
            let y = parseInt(datestring[0])
            let dl = new Date()
            dl.setFullYear(y)
            dl.setMonth(m-1)
            dl.setDate(d)
            let today = new Date()
            let applicableDate= dl >= today
            if ((p.status === "open" || p.status === "hiring") && applicableDate) {
                applicableProjects.push(<><Grid item><ProjectDisplay project={p} /></Grid></>)
            }
            else {
                expiredProjects.push(<><Grid item><ProjectDisplay project={p} /></Grid></>)
            }
        }
        //setCurrentPos(i)

        // @ts-ignore
        return (
            <Box sx={{flexGrow:1}}>
                <Box ref={topRef} />
                <Grid
                    container direction={'column'}
                    spacing={2} justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Grid item>
                        <Header
                            color={'primary.main'}
                            fontWeight={'bold'}
                            mb={0} mt={1} variant={'h3'}
                        >
                            Open For Application
                        </Header>
                    </Grid>
                    {/*@ts-ignore*/}
                    <Grid xs={6} container direction={'column'} spacing={2} sx={{mt:2}}>
                        <Divider sx={{
                            mb:2, borderBottomWidth:2.5,  bgcolor:'primary.dark', borderRadius:3
                        }}/>
                        {/* this fragment prevents some weird typescript error from occurring */}
                        {(applicableProjects.length > 0)
                            ? applicableProjects
                            : <Grid item><Typography variant='h5'> No projects currently open for applications </Typography></Grid>
                        }
                    </Grid>
                    <Grid item mt={3}>
                        <Header color={'primary.main'} fontWeight={'bold'} mb={0} mt={3} variant={'h3'}>
                            Previous Projects
                        </Header>
                    </Grid>
                    <Grid xs={6} container direction={'column'} spacing={2} sx={{my:2}}     >
                        <Divider sx={{
                            mb:2, borderBottomWidth:2.5,  bgcolor:'primary.dark', borderRadius:3
                        }}/>
                        {expiredProjects}
                    </Grid>
                    <Grid
                        container direction={'row'}
                        alignItems={'center'} justifyContent={'space-between'}
                        xs={8.5}
                    >
                        <Grid item xs={1}>
                            <IconButton
                                onClick={() => {pageBack()}}
                                disabled={(flag === 0)}
                            >
                                <KeyboardArrowLeft fontSize={'large'}/>
                            </IconButton>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography>Pages</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                onClick={() => {pageForward(i)}}
                                disabled={(projects.length-1 === i)}
                            >
                                <KeyboardArrowRight fontSize={'large'}/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Scroll to top button*/}
                <Fade in={trigger}>
                        <Box
                            onClick={scrollToTop}
                            role="presentation"
                            sx={{
                                position: 'fixed',
                                top: '4vh', left:'50%',
                                transform: `translate(-50%, -50%)`,
                                display: {
                                    xs:'none',
                                    md:'flex'
                                }
                            }}
                        >
                            <Fab variant="extended" color="primary" size='medium'>
                                <KeyboardArrowUpSharpIcon />
                                <Typography> &nbsp; To Top</Typography>
                            </Fab>
                        </Box>
                </Fade>

                <Box
                    role="presentation"
                    sx={{display:{md:'flex', xs:'none'}}}
                    onClick={() => {rAS("org",'/org/'+auth.netid+'/propose', {sublink:'/propose'} )}}
                >
                    <Fab color='primary' style={{transform: `scale(1.4)`}} sx={
                        {position:'fixed', bottom:32, right:32}
                    }>
                        <Tooltip title="Propose Project" placement='top' arrow>
                            <SendIcon fontSize={'large'} sx={{paddingLeft:.5}}/>
                        </Tooltip>
                    </Fab>
                </Box>
            </Box>
        )
    }
    else if (message) {
        return <Typography variant={'h4'} color={'primary.main'}>{message}</Typography>
    }
    else {
        return(
            <Loading open={(projects)?true:false} />
        )
    }

}


function ProjectDisplay(props:{project:Project}) {
    const nav = useNavigate()

    let p = props.project
    let info = [
        {label: "Deadline", info:p.deadline},
        {label: "Owner", info:p.poster},
        {label: "Organization", info:p.organization},
        {label: "Funding", info:p.funding},
        {label: "Description", info:p.description}
    ]
    return (
        <InfoDisplay title={p.title} info={info}>
            <Button variant={'contained'} size={'medium'} sx={{mt:3, mb:1}} onClick={() => {
                nav('/apply/'+p.id)
            }}>
                <Typography variant={'h6'}>
                    Apply
                </Typography>
            </Button>
        </InfoDisplay>
    )
}






